import React from 'react'
import { useTranslate } from '~/hooks'
import Metadata from '~/components/Metadata'
import ErrorNotFoundPage from '~/components/Error/NotFound/Page'
import nextContentWrapper from 'graphql-cms/nextContentWrapper'
import type { InferNextPage } from '~/types'
import { getAnnouncementBar } from 'graphql-cms/queries'

export const getStaticProps = nextContentWrapper.getStaticProps(async () => ({
  props: {
    announcementBarConfig: await getAnnouncementBar(),
  },
}))

const NotFound: InferNextPage<typeof getStaticProps> = () => {
  const translate = useTranslate()

  return (
    <>
      <Metadata title={translate('error.not_found.page_title')} />
      <ErrorNotFoundPage title={translate('error.not_found.page_title')} />
    </>
  )
}

export default NotFound
